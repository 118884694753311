.marker-pin2 {
   width: 28px;
   height: 28px;
   border-radius: 50% 50% 50% 0;
   //background: #c30b82;
   position: absolute;
   transform: rotate(-45deg);
   left: 50%;
   top: 50%;
   margin: -17px 0 0 -14px;
}
// to draw white circle
.marker-pin2::after {
   content: '';
   top: 3px;
   left: 3px;
   width: 22px;
   height: 22px;
   //margin: 4px 0 0 4px;
   background: rgba(20, 20, 20, .4);
   border: 1px solid rgba(20, 20, 20, .6);
   position: absolute;
   border-radius: 50%;
}

.marker-pin {
   width: 32px;
   height: 32px;
   border-radius: 50% 50% 50% 0;
   //background: #c30b82;
   position: absolute;
   transform: rotate(-45deg);
   left: 50%;
   top: 50%;
   margin: -15px 0 0 -15px;
}
// to draw white circle
.marker-pin::after {
   content: '';
   width: 24px;
   height: 24px;
   margin: 4px 0 0 4px;
   background: rgba(20, 20, 20, .4);
   border: 1px solid rgba(20, 20, 20, .6);
   position: absolute;
   border-radius: 50%;
}

// to align icon
.custom-div-icon img {
   position: absolute;
   //width: 22px;
   left: 0;
   right: 0;
   margin: 8px auto;
   text-align: center;
}

.marker-icon {
   width: 24px;
   height: 24px;
   border-radius: 50% 50% 50% 0;
   //background: #c30b82;
   position: relative;
   transform: rotate(-45deg);
   left: 50%;
   top: 50%;
   margin: -15px 0 0 -15px;
}
// to draw white circle
.marker-icon::after {
   content: '';
   top: 0;
   left: 0;
   width: 20px;
   height: 20px;
   margin: 2px 0 0 2px;
   background: rgba(20, 20, 20, .4);
   border: 1px solid rgba(20, 20, 20, .6);
   position: absolute;
   border-radius: 50%;
}

// to align icon
.custom-icon img {
   position: absolute;
   //width: 22px;
   top: 0;
   left: 0;
   right: 0;
   //margin: 8px auto;
   text-align: center;
}