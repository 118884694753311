@media print {

  header {
    display: none !important;
  }

  .no-print {
    display: none !important;
  }
}
.progress {
  position: absolute;
  top: 50%;
  left: 50%;
}

a[title~="Semicircle"] {
  background-image: url(sector.png) !important;
  background-size: 12px 14px !important;
}

